import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AudioFileComponent } from './audio-file/audio-file.component';
import { AudioFileSaveComponent } from './audio-file-save/audio-file-save.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FirmwareVersionComponent } from './firmware-version/firmware-version.component';
import { FirmwareVersionUpdateComponent } from './firmware-version-update/firmware-version-update.component';
import { LoginComponent } from './login/login.component';
import { OauthCallbackComponent } from './oauth-callback/oauth-callback.component';
import { EmotionFileComponent } from './emotion-file/emotion-file.component';
import { EmotionFileSaveComponent } from './emotion-file-save/emotion-file-save.component';
import { UserComponent } from './user/user.component';
import { UserSaveComponent } from './user-save/user-save.component';
import { GatewayComponent } from './gateway/gateway.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RedeemCodeComponent } from './redeem-code/redeem-code.component';
import { RedeemCodeSaveComponent } from './redeem-code-save/redeem-code-save.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatButtonModule, MatCheckboxModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatNativeDateModule,
  MatRippleModule
} from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { StatusComponent } from './status/status.component';
// import { DetectIntentLogComponent } from './detect-intent-log/detect-intent-log.component';

@NgModule({
  declarations: [
    AppComponent,
    AudioFileComponent,
    AudioFileSaveComponent,
    FirmwareVersionComponent,
    FirmwareVersionUpdateComponent,
    LoginComponent,
    OauthCallbackComponent,
    EmotionFileComponent,
    EmotionFileSaveComponent,
    UserComponent,
    UserSaveComponent,
    GatewayComponent,
    RedeemCodeComponent,
    RedeemCodeSaveComponent,
    StatusComponent,
    // DetectIntentLogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    NgbModule,
    MatSnackBarModule
  ],
  providers: [
    MatDatepickerModule
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
