import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import {HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router
  ) { }

  saveToken(token) {
    localStorage.setItem('id_token', token);
  }

  saveMyNonce(nonce) {
    localStorage.setItem('nonce', nonce);
  }

  getToken() {
    const token = localStorage.getItem('id_token');
    if (this.checkToken(token)) {
      return token;
    } else {
      return '';
    }
  }

  getMyNonce() {
    return localStorage.getItem('nonce');
  }

  private tokenIsExpired(token) {
    if (!token) {
      return true;
    }
    const decoded = jwt_decode(token);
    const expiredDate = decoded.exp;
    const toMilliSecond = 1000;
    return ( new Date().getTime() > (expiredDate * toMilliSecond) );
  }

  private checkToken(token): boolean {
    if (this.tokenIsExpired(token)) {
      this.router.navigate(['login']).then(() => {});
      return false;
    }
    return true;
  }

  checkNonce(token) {
    const decoded = jwt_decode(token);
    const tokenNonce = decoded.nonce;
    const myNonce = this.getMyNonce();
    return (tokenNonce === myNonce);
  }

  checkRole(token) {
    const decoded = jwt_decode(token);
    const role = decoded.role;
    return role === 'admin';
  }

  getHttpOption() {
    const token = this.getToken();
    return !!token ? {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      })
    } : {};
  }
}

@Injectable({
  providedIn: 'root'
})
export class MustLogin implements CanActivate {

  constructor(
    private authService: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return !!this.authService.getToken();
  }

}
