import { UtilService } from './util.service';
import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from './baseUrl';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmotionService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private utilService: UtilService
  ) {
  }

  getEmotions(pageOffset, pageSize, filter = '') {
    const currentPage = pageOffset + 1;
    let url = baseUrl.concat('/emotionFiles?page=', currentPage, '&max=', pageSize);
    if (filter) {
      url = url.concat('&filterMessage=', encodeURIComponent(filter));
    }
    return this.http.get(url, this.authService.getHttpOption()).toPromise().then(
      result => result).catch(
      errorResponse => {
        const body = errorResponse.error;
        const errorResult = 'code '.concat(body.error.statusCode, '. message: ', body.error.message);
        throw Error(errorResult);
      });
  }

  getEmotionById(id) {
    const url = baseUrl + '/emotionFiles/' + id;
    return this.http.get(url, this.authService.getHttpOption());
  }

  createEmotion(data) {
    if (!environment.production) {
      console.log(data.gifFileKey);
      this.utilService.openSnackBar(`please create file emotion key at:
      "obo-nlp-server/storage/${data.gifFileKey}"
      you can copy file name at browser console.
      You must be create the file completely before press 'OK' button.`);
    }
    const url = baseUrl + '/emotionFiles';
    return this.http.post(url, data, this.authService.getHttpOption());
  }

  editEmotion(id, data) {
    const url = baseUrl + '/emotionFiles/' + id;
    return this.http.patch(url, data, this.authService.getHttpOption());
  }

  deleteEmotion(id) {
    const url = baseUrl + '/emotionFiles/' + id;
    return this.http.delete(url, this.authService.getHttpOption());
  }

  createEmotionPack() {
    const url = baseUrl + '/emotionPack';
    return this.http.post(url, {}, this.authService.getHttpOption());
  }
}
