import {Component, OnInit} from '@angular/core';
import {AudioService} from '../audio.service';
import {ErrorResponseService} from '../error-response.service';
import { UtilService } from '../util.service';

interface Audio {
  id: string;
  message: string;
  audioKey: string;
  emotion: string;
  createdDate: number;
  lastUpdated: number;
  downloadUrl: string;
}

interface GetAudiosResponse {
  audioFiles: Audio[];
  totalPage: number;
  currentPage: number;
  totalItem: number;
  audioPackName: string;
  audioPackStatus: string;
}

interface CreateAudioPackResponse {
  message: string;
}

@Component({
  selector: 'app-audio-file',
  templateUrl: './audio-file.component.html',
  styleUrls: ['./audio-file.component.css']
})
export class AudioFileComponent implements OnInit {
  titleName = 'Audio Files Detail';
  audioFiles: Audio[];
  getAudioErrorMessage: string;
  pageSizes = [10, 20, 30];
  filterMessage = '';
  selectedPageSize = 10;
  pageOffset = 0;
  totalItem: number;
  audioPackName: string;
  audioPackStatus: string;

  constructor(
    private audioService: AudioService,
    private errorResponseService: ErrorResponseService,
    private utilService: UtilService
  ) {
  }

  ngOnInit() {
    this.getAudios();
  }

  getAudios() {
    this.audioService.getAudios(this.pageOffset, this.selectedPageSize).subscribe(
      (response: GetAudiosResponse) => {
        this.getAudioErrorMessage = '';
        this.audioFiles = response.audioFiles;
        this.totalItem = response.totalItem;
        this.audioPackName = response.audioPackName;
        this.audioPackStatus = response.audioPackStatus;
      },
      errorResponse => {
        this.getAudioErrorMessage = 'Fail to get audio file.' + '\n' + this.errorResponseService.detailString(errorResponse);
      });
  }

  getAudiosWithFilter() {
    this.audioService.getAudios(this.pageOffset, this.selectedPageSize, this.filterMessage).subscribe(
      (response: GetAudiosResponse) => {
        this.getAudioErrorMessage = '';
        this.audioFiles = response.audioFiles;
        this.totalItem = response.totalItem;

      },
      errorResponse => {
        this.getAudioErrorMessage = 'Fail to get audio file.' + '\n' + this.errorResponseService.detailString(errorResponse);
      });
  }

  onPageChange(event) {
    this.pageOffset = event.offset;
    this.getAudios();
  }

  deleteAudio(id) {
    if (window.confirm('Are you sure you want to delete the audio file?')) {
      this.audioService.deleteAudio(id).subscribe(
        () => {
          this.getAudios();
        },
        errorResponse => {
          this.utilService.openSnackBar('Fail to delete audio file.' + '\n' + this.errorResponseService.detailString(errorResponse));
        });
    }
  }

  createAudioPack() {
    this.audioService.createAudioPack().subscribe(
      (response: CreateAudioPackResponse) => {
        this.utilService.openSnackBar(response.message);
      },
      errorResponse => {
        this.utilService.openSnackBar('Failed to create audio pack file.' + '\n' + this.errorResponseService.detailString(errorResponse));
      });
  }
}
