import { UtilService } from './../util.service';
import {Component, OnInit} from '@angular/core';
import {EmotionService} from '../emotion.service';
import {ErrorResponseService} from '../error-response.service';

interface Emotion {
  id: string;
  emotion: string;
  gifFileKey: string;
  createdDate: number;
  lastUpdated: number;
}

interface GetEmotionsResponse {
  emotionFiles: Emotion[];
  totalPage: number;
  currentPage: number;
  totalItem: number;
  emotionPackName: string;
  emotionPackStatus: string;
}
interface CreateEmotionPackResponse {
  message: string;
}

@Component({
  selector: 'app-emotion-file',
  templateUrl: './emotion-file.component.html',
  styleUrls: ['./emotion-file.component.css']
})
export class EmotionFileComponent implements OnInit {
  titleName = 'Emotion File Detail';
  emotionFiles: Emotion[];
  filterMessage = '';
  getEmotionErrorMessage;
  pageSizes = [10, 20, 30];
  selectedPageSize = 10;
  pageOffset = 0;
  totalItem: number;
  emotionPackName: string;
  emotionPackStatus: string;

  constructor(
    private emotionService: EmotionService,
    private errorResponseService: ErrorResponseService,
    private utilService: UtilService
  ) {
  }

  ngOnInit() {
    this.getEmotions();
  }

  getEmotions() {
    this.emotionService.getEmotions(this.pageOffset, this.selectedPageSize)
      .then(
        (response: GetEmotionsResponse) => {
          this.getEmotionErrorMessage = '';
          this.emotionFiles = response.emotionFiles;
          this.totalItem = response.totalItem;
          this.emotionPackName = response.emotionPackName;
          this.emotionPackStatus = response.emotionPackStatus;
        }).catch(
      errorResponse => {
        const message = 'Failed to get emotion file. ';
        this.getEmotionErrorMessage = message.concat(errorResponse);
      });
  }

  getEmotionsWithFilter() {
    this.emotionService.getEmotions(this.pageOffset, this.selectedPageSize, this.filterMessage)
      .then(
        (response: GetEmotionsResponse) => {
          this.getEmotionErrorMessage = '';
          this.emotionFiles = response.emotionFiles;
          this.totalItem = response.totalItem;
        }).catch(
      errorResponse => {
        const message = 'Failed to get emotion file. ';
        this.getEmotionErrorMessage = message.concat(errorResponse);
      });
  }

  onPageChange(event) {
    this.pageOffset = event.offset;
    this.getEmotions();
  }

  deleteEmotion(id) {
    if (window.confirm('Are you sure you want to delete the emotion file?')) {
      this.emotionService.deleteEmotion(id).subscribe(
        () => {
          this.getEmotions();
        },
        errorResponse => {
          this.utilService.openSnackBar('Fail to delete emotion file.' + '\n' + this.errorResponseService.detailString(errorResponse));
        });
    }
  }

  createEmotionPack() {
    this.emotionService.createEmotionPack().subscribe(
      (response: CreateEmotionPackResponse) => {
        this.utilService.openSnackBar(response.message);
      },
      errorResponse => {
        this.utilService.openSnackBar('Failed to create emotion pack file.' + '\n' + this.errorResponseService.detailString(errorResponse));
      });
  }

}
