import {Injectable} from '@angular/core';
import {baseUrl} from './baseUrl';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {flatMap, map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {Observable} from 'rxjs';
import * as uuid from 'uuid';

interface PreSigned {
  url: string;
  fields: {
    key: string
  };
}

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {
  }

  uploadFileToServer(name, file): Observable<string> {
    // if (!environment.production) {
    //   return this.stubUploadFile(name, file);
    // } else {
    return this.uploadFile(name, file);
    // }
  }

  // private stubUploadFile(name, file): Observable<string> {
  //   this.utilService.openSnackBar('This mode cannot upload file to server.');
  //   return new Observable((observer) => {
  //     observer.next(`${name}/temp/${uuid.v4()}`);
  //   });
  // }

  private uploadFile(name, file): Observable<string> {
    const url = baseUrl + '/audioFiles/preSignedUrl/' + name;
    let preSigned: PreSigned;
    return this.http.get(url, this.authService.getHttpOption()).pipe(
      flatMap((ps: PreSigned) => {
        console.log(preSigned);
        preSigned = ps;
        const uploadUrl = ps.url;
        const fields = ps.fields;
          const form = new FormData();
          for (const property in fields) {
            if (fields.hasOwnProperty(property)) {
              form.append(property, fields[property]);
            }
          }
          form.append('file', file);
          return this.http.put(uploadUrl, file);
        }
      ),
      map(() => preSigned.fields.key)
    );
  }
}
