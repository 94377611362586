import { UtilService } from './../util.service';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {RedeemCodeService} from '../redeem-code.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {ErrorResponseService} from '../error-response.service';
import {Location} from '@angular/common';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

interface RedeemCodeResponse {
  id: string;
  code: string;
  macAddress: string;
  group: string;
  expireDate: Date;
  isActivated: boolean;
}

@Component({
  selector: 'app-login-save',
  templateUrl: './redeem-code-save.component.html',
  styleUrls: ['./redeem-code-save.component.css']
})
export class RedeemCodeSaveComponent implements OnInit {
  titleName: string;
  id: string;

  groups: any[] = [];
  filteredGroups: any[] = [];
  question = 'Would you like to add "';

  newRedeemForm = this.formBuilder.group({
    quantity: 1,
    group: ''
  });

  redeemForm = this.formBuilder.group({
    id: [{
      value: null,
      disabled: true
    }],
    code: [{
      value: null,
      disabled: true
    }],
    macAddress: '',
    group: '',
    expireDate: '',
    isActivated: ''
  });

  requiredFieldError: string;
  

  constructor(
    private activatedRoute: ActivatedRoute,
    private redeemCodeService: RedeemCodeService,
    private formBuilder: FormBuilder,
    private router: Router,
    private errorResponseService: ErrorResponseService,
    // tslint:disable-next-line:variable-name
    public _location: Location,
    private utilService: UtilService
  ) {

  }

  async ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id !== 'new') {
        this.titleName = 'Edit Redeem Code';
        this.showDataOnInputField();
      } else {
        this.titleName = 'Create Redeem Code';
      }
    });
    await this.setGroupsForm();
  }

  formChangeHandle(form) {
    this.filteredGroups = this.redeemCodeService.filterGroupsFormOptions(form.group, this.groups);
  }

  setGroupsForm() {
    this.redeemCodeService.getRedeemCodes(0, 0).subscribe((redeemResponses) => {
      this.groups = this.redeemCodeService.getRedeemGroup(redeemResponses);
      this.filteredGroups = this.groups;
      this.newRedeemForm.valueChanges.pipe().subscribe((form) => this.formChangeHandle(form));
      this.redeemForm.valueChanges.pipe().subscribe((form) => this.formChangeHandle(form));
    });
  }

  onSubmit() {
    if (this.id === 'new') {
      if (this.checkRequiredField()) {
        this.createRedeemCode();
      }
    } else {
      if (this.checkRequiredField()) {
        this.editRedeemCode();
      }
    }
  }

  checkRequiredField() {
    const quantity = this.newRedeemForm.value.quantity;
    if (this.id === 'new') {
      if (quantity.length === 0) {
        this.requiredFieldError = 'quantity is required';
        return false;
        // tslint:disable-next-line:radix
      } else if (isNaN(Number.parseInt(quantity))) {
        this.requiredFieldError = 'quantity is not number';
        return false;
        // tslint:disable-next-line:radix
      } else if (Number.isInteger(Number.parseInt(quantity)) === false || quantity < 1) {
        this.requiredFieldError = 'quantity is not valid number';
        return false;
      } else {
        this.requiredFieldError = '';
        return true;
      }
    } else {
      this.requiredFieldError = '';
      return true;
    }
  }

  showDataOnInputField() {
    this.redeemCodeService.getRedeemCodeById(this.id).subscribe(
      (response: RedeemCodeResponse) => {
        this.requiredFieldError = '';
        this.redeemForm.patchValue({
          id: response.id,
          code: response.code,
          macAddress: response.macAddress,
          group: response.group,
          expireDate: response.expireDate,
          isActivated: response.isActivated
        });
      },
      errorResponse => {
        this.requiredFieldError = 'Fail to get redeem information.' + '\n' + this.errorResponseService.detailString(errorResponse);
      });
  }

  enter() {
    if (this.id === 'new') {
      this.redeemCodeService.enter(this.filteredGroups, this.newRedeemForm);
    } else {
      this.redeemCodeService.enter(this.filteredGroups, this.redeemForm);
    }
  }

  optionSelected(option) {
    this.redeemCodeService.optionSelected(option, this.groups, this.newRedeemForm, this.redeemCodeService);
  }

  createRedeemCode() {
    const data = {
      quantity: this.newRedeemForm.value.quantity,
      group: this.newRedeemForm.value.group
    };
    this.redeemCodeService.createRedeemCode(data.quantity, data.group).subscribe(
      () => {
        this.utilService.openSnackBar('Create new redeem successful\n' + 'quantity: ' + data.quantity + ' group: ' + data.group);
        this.router.navigate(['redeem-code']).then(r => {});
      },
      errorResponse => {
        this.utilService.openSnackBar('Fail to create redeem.' + '\n' + this.errorResponseService.detailString(errorResponse));
      });
  }

  editRedeemCode() {
    const data = {
      id: this.id,
      macAddress: this.redeemForm.value.macAddress,
      expireDate: this.redeemForm.value.expireDate,
      isActivated: this.redeemForm.value.isActivated
    };
    this.redeemCodeService.editRedeemCode(this.id, data).subscribe(
      () => {
        this.utilService.openSnackBar('Edit redeem successful');
        this.router.navigate(['redeem-code']).then(r => {
        });
      },
      errorResponse => {
        this.utilService.openSnackBar('Fail to edit redeem.' + '\n' + this.errorResponseService.detailString(errorResponse));
      });
  }
}
