import { UtilService } from './../util.service';
import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {FirmwareService} from '../firmware.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorResponseService} from '../error-response.service';

interface BrandAndVersionMapping {
  id: string;
  packageName: string;
  firmwareVersionId: string;
  brand: string;
  version: string;
}

interface FirmwareVersion {
  id: string;
  name: string;
  packageName: string;
  launcherActivityName: string;
  isFirmware: boolean;
  isSystemApp: boolean;
  batteryRequired: number;
  minimumVersion: string;
  latestVersion: string;
  lastUpdated: number;
  downloadUrl: string;
  brandAndVersionMappingList: BrandAndVersionMapping[];
}

@Component({
  selector: 'app-firmware-version-update',
  templateUrl: './firmware-version-update.component.html',
  styleUrls: ['./firmware-version-update.component.css']
})
export class FirmwareVersionUpdateComponent implements OnInit {
  titleName = 'Firmware Version Information';
  id: string;
  brandAndVersionMappingList: BrandAndVersionMapping[];

  firmwareVersionInfoForm = this.formBuilder.group({
    name: ['', Validators.required],
    packageName: ['', Validators.required],
    launcherActivityName: ['', Validators.required],
    isFirmware: [false, Validators.required],
    isSystemApp: [false, Validators.required],
    batteryRequired: [0, Validators.required],
    minimumVersion: ['', Validators.required],
    latestVersion: ['', Validators.required],
    downloadUrl: ['', Validators.required]
  });

  gatewayInfoForm = this.formBuilder.group({
    brand: ['', Validators.required],
    version: ['', Validators.required]
  });

  constructor(
    private formBuilder: FormBuilder,
    private firmwareService: FirmwareService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private errorResponseService: ErrorResponseService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id !== 'new') {
        this.updateDataOnPage();
      }
    });
  }

  updateDataOnPage() {
    this.firmwareService.getFirmwareVersionById(this.id).subscribe(
      (response: FirmwareVersion) => {
        this.firmwareVersionInfoForm.patchValue({
          name: response.name,
          packageName: response.packageName,
          launcherActivityName: response.launcherActivityName,
          isFirmware: response.isFirmware,
          isSystemApp: response.isSystemApp,
          batteryRequired: response.batteryRequired,
          minimumVersion: response.minimumVersion,
          latestVersion: response.latestVersion,
          downloadUrl: response.downloadUrl
        });
        this.brandAndVersionMappingList = response.brandAndVersionMappingList;
      },
      errorResponse => {
        this.utilService.openSnackBar('Fail to get firmware version information.\n' + this.errorResponseService.detailString(errorResponse));
      });
  }

  saveAndFinish() {
    if (this.id === 'new') {
      this.createFirmwareVersion('finish');
    } else {
      this.editFirmwareVersion();
    }
  }

  createFirmwareVersion(nextAction?: string) {
    if (this.checkRequiredField(this.firmwareVersionInfoForm.status)) {
      const data = this.firmwareVersionInfoForm.value;
      this.firmwareService.createFirmwareVersion(data).subscribe(
        (response: { id: string }) => {
          this.utilService.openSnackBar('Save successful.');
          if (nextAction === 'finish') {
            this.router.navigate(['/firmware']);
          } else {
            this.id = response.id;
            this.updateDataOnPage();
          }
        },
        errorResponse => {
          this.utilService.openSnackBar('Fail to save firmware version information.\n' + this.errorResponseService.detailString(errorResponse));
        });
    }
  }

  editFirmwareVersion() {
    if (this.checkRequiredField(this.firmwareVersionInfoForm.status)) {
      const data = {
        name: this.firmwareVersionInfoForm.value.name,
        packageName: this.firmwareVersionInfoForm.value.packageName,
        launcherActivityName: this.firmwareVersionInfoForm.value.launcherActivityName,
        isFirmware: this.firmwareVersionInfoForm.value.isFirmware,
        isSystemApp: this.firmwareVersionInfoForm.value.isSystemApp,
        batteryRequired: this.firmwareVersionInfoForm.value.batteryRequired,
        minimumVersion: this.firmwareVersionInfoForm.value.minimumVersion,
        latestVersion: this.firmwareVersionInfoForm.value.latestVersion,
        downloadUrl: this.firmwareVersionInfoForm.value.downloadUrl,
        brandAndVersionList: this.brandAndVersionMappingList
      };
      this.firmwareService.editFirmwareVersion(this.id, data).subscribe(
        () => {
          this.utilService.openSnackBar('Save successful.');
          this.router.navigate(['/firmware']);
        },
        errorResponse => {
          this.utilService.openSnackBar('Fail to save firmware version information.\n' + this.errorResponseService.detailString(errorResponse));
        });
    }
  }

  checkRequiredField(formStatus) {
    if (formStatus === 'VALID') {
      return true;
    } else {
      this.utilService.openSnackBar('Please fill information in required field.');
      return false;
    }
  }

  addBrandAndVersion() {
    if (this.checkRequiredField(this.gatewayInfoForm.status)) {
      const data = this.gatewayInfoForm.value;
      this.firmwareService.addBrandAndVersion(this.id, data).subscribe(
        () => {
          this.updateDataOnPage();
          this.gatewayInfoForm.reset();
        },
        errorResponse => {
          this.utilService.openSnackBar('Fail to add gateway brand and version.\n' + this.errorResponseService.detailString(errorResponse));
        });
    }
  }

  deleteBrandAndVersion(mappingId) {
    if (window.confirm('Are you sure you want to delete this gateway brand and version?')) {
      this.firmwareService.deleteBrandAndVersion(this.id, mappingId).subscribe(
        () => {
          this.updateDataOnPage();
        },
        errorResponse => {
          this.utilService.openSnackBar('Fail to delete gateway brand and version.\n' + this.errorResponseService.detailString(errorResponse));
        });
    }
  }
}
