import { UtilService } from './../util.service';
import { Component, OnInit } from '@angular/core';
import {UserService} from '../user.service';
import {ErrorResponseService} from '../error-response.service';

interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  role: string;
  createAt: number;
  isActivated: boolean;
}

interface GetUsersResponse {
  users: User[];
  totalPage: number;
  currentPage: number;
  totalItem: number;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  [x: string]: any;
  titleName = 'Users Detail';
  users: User[];
  getUserErrorMessage = '';
  pageSizes = [10, 20, 30];
  selectedPageSize = 10;
  pageOffset = 0;
  totalItem: number;

  constructor(
    private userService: UserService,
    private errorResponseService: ErrorResponseService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.userService.getUsers(this.pageOffset, this.selectedPageSize).subscribe(
      (response: GetUsersResponse) => {
        this.getUserErrorMessage = '';
        this.users = response.users;
        this.totalItem = response.totalItem;
      },
      errorResponse => {
        this.getUserErrorMessage = 'Fail to get user list.' + '\n' + this.errorResponseService.detailString(errorResponse);
      });
  }

  onPageChange(event) {
    this.pageOffset = event.offset;
    this.getUsers();
  }

  deleteUser(id) {
    if (window.confirm('Are you sure you want to delete this user?')) {
      this.userService.deleteUser(id).subscribe(
        () => {
          this.utilService.openSnackBar('Delete user successful.');
          this.getUsers();
        },
        errorResponse => {
          this.utilService.openSnackBar('Fail to delete user.' + '\n' + this.errorResponseService.detailString(errorResponse));
        });
    }
  }

}
