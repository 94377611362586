import { Component, OnInit } from '@angular/core';
import * as uuid from 'uuid';
import {AuthService} from '../auth.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  parameter = `/auth?response_type=id_token+token&client_id=${environment.auth.clientId}&scope=openid+role&prompt=login&redirect_uri=${environment.auth.redirectUri}`;
  loginUrl = environment.auth.loginUrl.concat(this.parameter);
  constructor(
    private authService: AuthService
  ) { }

  ngOnInit() {
    const nonce = uuid.v4();
    this.authService.saveMyNonce(nonce);
    window.location.href = this.loginUrl.concat(`&nonce=${nonce}`);
  }

}
