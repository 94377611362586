import { Injectable } from '@angular/core';
import {baseUrl} from './baseUrl';
import {HttpClient} from '@angular/common/http';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class GatewayService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getGateways(pageOffset, pageSize) {
    const currentPage = pageOffset + 1;
    const url = baseUrl + '/gateways?page=' + currentPage + '&max=' + pageSize;
    return this.http.get(url, this.authService.getHttpOption());
  }

  uploadGatewayFile(data) {
    const url = baseUrl + '/gateways';
    return this.http.patch(url, data, this.authService.getHttpOption());
  }
}
