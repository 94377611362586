import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {AudioService} from '../audio.service';
import {UploadFileService} from '../upload-file.service';
import {ErrorResponseService} from '../error-response.service';
import { UtilService } from '../util.service';

interface Audio {
  message: string;
  audioKey: string;
  emotion: string;
  createdDate: number;
  lastUpdated: number;
}

@Component({
  selector: 'app-audio-file-save',
  templateUrl: './audio-file-save.component.html',
  styleUrls: ['./audio-file-save.component.css']
})
export class AudioFileSaveComponent implements OnInit {
  titleName = ' Audio File';
  id: string;

  audioForm = this.formBuilder.group({
    message: ['', Validators.required],
    emotion: ['', Validators.required],
    audioKey: ['', Validators.required]
  });

  errorUploadMessage: string;
  uploadComplete: string;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private audioService: AudioService,
    private uploadFileService: UploadFileService,
    private router: Router,
    private errorResponseService: ErrorResponseService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id !== 'new') {
        this.titleName = 'Edit' + this.titleName;
        this.showDataOnInputField(this.id);
      } else {
        this.titleName = 'Create' + this.titleName;
      }
    });
  }

  showDataOnInputField(id) {
    this.audioService.getAudioById(id).subscribe(
      (response: Audio) => {
        this.audioForm.patchValue({
          message: response.message,
          emotion: response.emotion,
          audioKey: response.audioKey
        });
      },
      errorResponse => {
        this.utilService.openSnackBar('Fail to get audio file information.' + '\n' + this.errorResponseService.detailString(errorResponse));
      });
  }

  uploadFile(event) {
    this.uploadComplete = '';
    const file = event.target.files[0];
    const fileType = file.type;
    if (fileType !== 'audio/wav' && fileType != 'audio/mpeg') {
      this.errorUploadMessage = 'Please choose .wav or .mp3 file type to upload.';
    } else {
      this.errorUploadMessage = 'Uploading, please wait.';

      this.uploadFileService.uploadFileToServer('audio', file).subscribe(
        filePath => {
          this.audioForm.patchValue({
            audioKey: filePath
          });
          this.uploadComplete = 'Upload file complete.';
          this.errorUploadMessage = '';
        },
        errorResponse => {
          this.errorUploadMessage = 'Fail to upload file to server.' + '\n' + this.errorResponseService.detailString(errorResponse);
        });
    }
  }

  onSubmit() {
    if (this.audioForm.status === 'INVALID') {
      this.utilService.openSnackBar('Please fill information in all fields.');
    } else {
      const data = this.audioForm.value;
      if (this.id === 'new') {
        this.audioService.createAudio(data).subscribe(
          () => {
            this.utilService.openSnackBar('Create new audio file successful');
            this.router.navigate(['audio']);
          },
          errorResponse => {
            this.utilService.openSnackBar('Fail to create new audio file.' + '\n' + this.errorResponseService.detailString(errorResponse));
          });
      } else {
        this.audioService.editAudio(this.id, data).subscribe(
          () => {
            this.utilService.openSnackBar('Edit audio file successful.');
            this.router.navigate(['audio']);
          },
          errorResponse => {
            this.utilService.openSnackBar('Fail to edit audio file.' + '\n' + this.errorResponseService.detailString(errorResponse));
          });
      }
    }
  }

}
