import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from './baseUrl';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getUsers(pageOffset, pageSize) {
    const currentPage = pageOffset + 1;
    const url = baseUrl + '/users?page=' + currentPage + '&max=' + pageSize;
    return this.http.get(url, this.authService.getHttpOption());
  }

  getUserById(id) {
    const url = baseUrl + '/users/' + id;
    return this.http.get(url, this.authService.getHttpOption());
  }

  createUser(data) {
    const url = baseUrl + '/users';
    return this.http.post(url, data, this.authService.getHttpOption());
  }

  editUser(id, data) {
    const url = baseUrl + '/users/' + id;
    return this.http.patch(url, data, this.authService.getHttpOption());
  }

  deleteUser(id) {
    const url = baseUrl + '/users/' + id;
    return this.http.delete(url, this.authService.getHttpOption());
  }
}
