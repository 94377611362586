import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AudioFileComponent } from './audio-file/audio-file.component';
import { AudioFileSaveComponent } from './audio-file-save/audio-file-save.component';
import { FirmwareVersionComponent } from './firmware-version/firmware-version.component';
import { FirmwareVersionUpdateComponent } from './firmware-version-update/firmware-version-update.component';
import { LoginComponent } from './login/login.component';
import { MustLogin } from './auth.service';
import { OauthCallbackComponent } from './oauth-callback/oauth-callback.component';
import { EmotionFileComponent } from './emotion-file/emotion-file.component';
import { EmotionFileSaveComponent } from './emotion-file-save/emotion-file-save.component';
import { UserComponent } from './user/user.component';
import { UserSaveComponent } from './user-save/user-save.component';
import { GatewayComponent } from './gateway/gateway.component';
import { RedeemCodeComponent } from './redeem-code/redeem-code.component';
import { RedeemCodeSaveComponent } from './redeem-code-save/redeem-code-save.component';
import { StatusComponent } from './status/status.component'
// import {DetectIntentLogComponent} from './detect-intent-log/detect-intent-log.component';


const routes: Routes = [
  { path: '', pathMatch: 'prefix', redirectTo: 'audio' },
  { path: 'audio', component: AudioFileComponent, canActivate: [MustLogin] },
  { path: 'audio/save/:id', component: AudioFileSaveComponent, canActivate: [MustLogin] },
  { path: 'emotion', component: EmotionFileComponent, canActivate: [MustLogin] },
  { path: 'emotion/save/:id', component: EmotionFileSaveComponent, canActivate: [MustLogin] },
  { path: 'user', component: UserComponent, canActivate: [MustLogin] },
  { path: 'user/save/:id', component: UserSaveComponent, canActivate: [MustLogin] },
  { path: 'gateway', component: GatewayComponent, canActivate: [MustLogin] },
  { path: 'firmware', component: FirmwareVersionComponent, canActivate: [MustLogin] },
  { path: 'firmware/update/:id', component: FirmwareVersionUpdateComponent, canActivate: [MustLogin] },
  { path: 'login', component: LoginComponent },
  { path: 'callback', component: OauthCallbackComponent },
  { path: 'redeem-code', component: RedeemCodeComponent },
  { path: 'redeem-code/save/:id', component: RedeemCodeSaveComponent },
  { path: 'status', component: StatusComponent },
  // {path: 'detect-intent-log', component: DetectIntentLogComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
