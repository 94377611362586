import { UtilService } from './../util.service';
import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {RedeemCodeService} from '../redeem-code.service';
import {ErrorResponseService} from '../error-response.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import dateFormat from 'dateformat';
import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

const EXPORT_FILE_NAME = 'JOOX Redeem Code';

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  constructor() {
  }

  static saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {Sheets: {data: worksheet}, SheetNames: ['data']};
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
    ExcelService.saveAsExcelFile(excelBuffer, excelFileName);
  }
}

interface RedeemCode {
  id: string;
  code: string;
  macAddress: string;
}

interface RedeemCodes {
  [index: number]: string;
}

@Component({
  selector: 'app-redeem-code',
  templateUrl: './redeem-code.component.html',
  styleUrls: ['./redeem-code.component.css']
})
export class RedeemCodeComponent implements OnInit {
  redeemCodes = [];
  titleName = 'Redeem Code';
  getRedeemCodeErrorMessage: string;
  pageSizes = [10, 20, 30, 50, 100];
  selectedPageSize = 10;
  pageOffset = 0;
  totalItem: number;
  columns;
  redeemCodesWithoutFilter;

  groups: string[];
  filteredGroups: string[];

  advanceFilterForm = this.formBuilder.group({
    activateFilter: '',
    notActivateFilter: '',
    group: ''
  });

  activateFilter = true;
  notActivateFilter = true;

  isLoading = true;

  // @ts-ignore
  @ViewChild('buttonsTemplate') buttonsTemplate: TemplateRef<any>;
  

  constructor(
    private redeemCodeService: RedeemCodeService,
    private errorResponseService: ErrorResponseService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private excelService: ExcelService,
    private utilService: UtilService
  ) {

  }

  exportAsXLSX(): void {
    const exportJSON = this.redeemCodes.map((value, index, arr) => {
      return {
        index: index + 1,
        id: value.id,
        code: value.code,
        macAddress: value.macAddress,
        group: value.group,
        expireDate: value.expireDate.replace(/\s/g, '')
      };
    });
    this.excelService.exportAsExcelFile(exportJSON, EXPORT_FILE_NAME);
  }

  async ngOnInit() {
    this.columns = [{prop: 'index'},
      {prop: 'code', name: 'Redeem Code'},
      {prop: 'macAddress'},
      {prop: 'group'},
      {prop: 'expireDate'},
      {prop: 'isActivatedIcon', name: 'Activate'},
      {prop: 'isExpired', name: 'Expired'},
      {prop: 'actions', name: 'Actions', cellTemplate: this.buttonsTemplate}];
    await this.getRedeemCodes();
  }

  deleteRedeemCode(value) {
    const confirmFlag = confirm('Are you sure? Do you want to delete?');
    if (!confirmFlag) {
      return;
    }
    this.redeemCodeService.deleteRedeemCode(value).subscribe(
      () => {
        this.utilService.openSnackBar('Delete redeem code successful.');
        this.getRedeemCodes();
      },
      errorResponse => {
        this.utilService.openSnackBar('Fail to delete redeem code.' + '\n' + this.errorResponseService.detailString(errorResponse));
      });
  }

  clearFilter() {
    this.redeemCodes = this.redeemCodesWithoutFilter;
  }

  isExpire(inputDate): boolean {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    inputDate = new Date(inputDate);
    inputDate.setHours(0, 0, 0, 0);
    return today >= inputDate;
  }

  isActivate(inputDate): boolean {
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    inputDate = new Date(inputDate);
    inputDate.setHours(0, 0, 0, 0);
    return today <= inputDate;
  }

  async getRedeemCodes() {
    await this.redeemCodeService.getRedeemCodes(this.pageOffset, this.selectedPageSize).subscribe(
      (redeemCodeResponses: any) => {
        this.getRedeemCodeErrorMessage = '';
        this.redeemCodes = [];
        this.redeemCodesWithoutFilter = [];
        let i = 1;
        // tslint:disable-next-line:forin
        for (const redeemCodeIndex in redeemCodeResponses) {
          const redeemCodeObj = redeemCodeResponses[redeemCodeIndex];
          redeemCodeObj.index = i++;
          redeemCodeObj.isActivate = this.isActivate(redeemCodeObj.expireDate) && redeemCodeObj.macAddress !== '';
          redeemCodeObj.isActivatedIcon = redeemCodeObj.isActivate ?
            '<i class="fas fa-check-circle fa-lg"></i>' :
            '<i class="fas fa-times-circle fa-lg"></i>';
          redeemCodeObj.isExpired = this.isExpire(redeemCodeObj.expireDate) ?
            '<i class="fas fa-circle fa-lg red-circle"></i> &nbsp; <span class="red-circle">Expired</span>' :
            '<i class="fas fa-circle fa-lg green-circle"></i> &nbsp; <span class="green-circle">Not Expired</span>';
          redeemCodeObj.expireDate = dateFormat(redeemCodeObj.expireDate, 'yyyy - mm - dd');
          this.redeemCodesWithoutFilter.push(redeemCodeObj);
          this.redeemCodes.push(redeemCodeObj);
        }
        this.groups = this.redeemCodeService.getRedeemGroup(this.redeemCodes);
        this.filteredGroups = this.groups;
        this.totalItem = redeemCodeResponses.length;
        this.isLoading = false;
        this.advanceFilterForm.valueChanges.pipe().subscribe(form => {
          this.filteredGroups = this.redeemCodeService.filterGroupsFormOptions(form.group, this.groups);
        });
      },
      errorResponse => {
        this.getRedeemCodeErrorMessage = 'Fail to get redeem code list.' + '\n' + this.errorResponseService.detailString(errorResponse);
      });
  }

  advanceFilterDatatable() {
    this.clearFilter();
    const activateFilter = this.activateFilter;
    const notActivateFilter = this.notActivateFilter;
    const group = this.advanceFilterForm.value.group;
    this.redeemCodes = this.redeemCodes.filter((redeemCode) => {
      const groupFlag = !group || (redeemCode.group && redeemCode.group.includes(group));
      const activeFlag = activateFilter && redeemCode.isActivate;
      const notActiveFlag = notActivateFilter && !redeemCode.isActivate;
      return groupFlag && (activeFlag || notActiveFlag);
    });
    this.pageOffset = 0;
  }

  filterDatatable(event) {
    this.clearFilter();
    const val = event.target.value.toLowerCase();
    const colsAmt = this.columns.length;
    const keys = Object.keys(this.redeemCodes[0]);
    this.redeemCodes = this.redeemCodes.filter((item) => {
      for (let i = 1; i < colsAmt; i++) {
        if (item[keys[i]]) {
          if (!val || item[keys[i]].toString().toLowerCase().indexOf(val) !== -1) {
            return true;
          }
        }
      }
    });
    this.pageOffset = 0;
  }

  enter() {
    this.redeemCodeService.enter(this.filteredGroups, this.advanceFilterForm);
  }

  optionSelected(option) {
    this.redeemCodeService.optionSelected(option, this.groups, this.advanceFilterForm, this.redeemCodeService);
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    }, (reason) => {

    });
  }

  log(item: any) {
    console.log(item);
  }
}
