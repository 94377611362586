import { Component, OnInit } from '@angular/core';
import {GatewayService} from '../gateway.service';
import {ErrorResponseService} from '../error-response.service';

interface Gateway {
  GW_ID: string;
  GW_PASS: string;
  properties: {
    Room_Type: string;
    SSID: string;
    CH: number;
    No: number;
  };
}

interface GetGatewaysResponse {
  gateways: Gateway[];
  totalPage: number;
  currentPage: number;
  totalItem: number;
}

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.css']
})
export class GatewayComponent implements OnInit {
  titleName = 'Gateway';
  gateways: Gateway[];
  getGatewayError;
  uploadComplete;
  errorUploadMessage;
  file;
  pageSizes = [10, 20, 30];
  selectedPageSize = 10;
  pageOffset = 0;
  totalItem: number;

  constructor(
    private gatewayService: GatewayService,
    private errorResponseService: ErrorResponseService
  ) { }

  ngOnInit() {
    this.getGateways();
  }

  getGateways() {
    this.gatewayService.getGateways(this.pageOffset, this.selectedPageSize).subscribe(
      (response: GetGatewaysResponse ) => {
        this.getGatewayError = '';
        this.gateways = response.gateways;
        this.totalItem = response.totalItem;
      },
      errorResponse => {
        this.getGatewayError = 'Fail to get gateway.' + '\n' + this.errorResponseService.detailString(errorResponse);
      });
  }

  onPageChange(event) {
    this.pageOffset = event.offset;
    this.getGateways();
  }

  fileSelected(event) {
    this.uploadComplete = '';
    this.errorUploadMessage = '';
    this.file = event.target.files[0];
  }

  uploadFile() {
    const form = new FormData();
    form.append('excelFile', this.file);
    this.uploadComplete = '';
    this.errorUploadMessage = 'Uploading, please wait.';
    this.gatewayService.uploadGatewayFile(form).subscribe(
      () => {
        this.errorUploadMessage = '';
        this.uploadComplete = 'Upload complete.';
        this.getGateways();
      },
      errorResponse => {
        this.errorUploadMessage = 'Fail to upload file to server.' + '\n' + this.errorResponseService.detailString(errorResponse);
      });
  }
}
