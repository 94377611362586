import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {baseUrl} from './baseUrl';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  getFirmwareVersions(pageOffset, pageSize) {
    const currentPage = pageOffset + 1;
    const url = baseUrl + '/firmware/versions?page=' + currentPage + '&max=' + pageSize;
    return this.http.get(url, this.authService.getHttpOption());
  }

  getFirmwareVersionById(id) {
    const url = baseUrl + '/firmware/versions/' + id;
    return this.http.get(url, this.authService.getHttpOption());
  }

  editFirmwareVersion(id, data) {
    const url = baseUrl + '/firmware/versions/' + id;
    return this.http.patch(url, data, this.authService.getHttpOption());
  }

  createFirmwareVersion(data) {
    const url = baseUrl + '/firmware/versions';
    return this.http.post(url, data, this.authService.getHttpOption());
  }

  deleteFirmwareVersion(id) {
    const url = baseUrl + '/firmware/versions/' + id;
    return this.http.delete(url, this.authService.getHttpOption());
  }

  addBrandAndVersion(firmwareVersionId, data) {
    const url = baseUrl + '/firmware/versions/' + firmwareVersionId + '/brand-version-mappings';
    return this.http.patch(url, data, this.authService.getHttpOption());
  }

  deleteBrandAndVersion(firmwareVersionId, mappingId) {
    const url = baseUrl + '/firmware/versions/' + firmwareVersionId + '/brand-version-mappings/' + mappingId;
    return this.http.delete(url, this.authService.getHttpOption());
  }
}
