import { UtilService } from './../util.service';
import { Component, OnInit } from '@angular/core';
import {FirmwareService} from '../firmware.service';
import {ErrorResponseService} from '../error-response.service';

interface FirmwareVersion {
  id: string;
  name: string;
  packageName: string;
  launcherActivityName: string;
  isFirmware: boolean;
  isSystemApp: boolean;
  batteryRequired: number;
  minimumVersion: string;
  latestVersion: string;
  createdDate: number;
  lastUpdated: number;
  downloadUrl: string;
}

interface GetFirmwareVersionsResponse {
  firmwareVersions: FirmwareVersion[];
  totalPage: number;
  currentPage: number;
  totalItem: number;
}

@Component({
  selector: 'app-firmware-version',
  templateUrl: './firmware-version.component.html',
  styleUrls: ['./firmware-version.component.css']
})
export class FirmwareVersionComponent implements OnInit {
  titleName = 'Firmware Version';
  firmwareVersions: FirmwareVersion[];
  getFirmwareErrorMessage = '';
  pageSizes = [10, 20, 30];
  selectedPageSize = 10;
  pageOffset = 0;
  totalItem: number;

  constructor(
    private firmwareService: FirmwareService,
    private errorResponseService: ErrorResponseService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.getFirmwareVersions();
  }

  getFirmwareVersions() {
    this.firmwareService.getFirmwareVersions(this.pageOffset, this.selectedPageSize).subscribe(
      (response: GetFirmwareVersionsResponse ) => {
        this.getFirmwareErrorMessage = '';
        this.firmwareVersions = response.firmwareVersions;
        this.totalItem = response.totalItem;
      },
      errorResponse => {
        this.getFirmwareErrorMessage = 'Fail to get firmware version. ' + this.errorResponseService.detailString(errorResponse);
      });
  }

  deleteFirmwareVersion(id) {
    if (window.confirm('Are you sure you want to delete this firmware version?')) {
      this.firmwareService.deleteFirmwareVersion(id).subscribe(
        () => {
          this.getFirmwareVersions();
        },
        errorResponse => {
          this.utilService.openSnackBar('Fail to delete firmware version.' + '\n' + this.errorResponseService.detailString(errorResponse));
        });
    }
  }

  onPageChange(event) {
    this.pageOffset = event.offset;
    this.getFirmwareVersions();
  }

}
