import { UtilService } from './../util.service';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {EmotionService} from '../emotion.service';
import {UploadFileService} from '../upload-file.service';
import {ErrorResponseService} from '../error-response.service';

@Component({
  selector: 'app-emotion-file-save',
  templateUrl: './emotion-file-save.component.html',
  styleUrls: ['./emotion-file-save.component.css']
})
export class EmotionFileSaveComponent implements OnInit {
  titleName = ' Emotion File';
  id: string;

  emotionForm = this.formBuilder.group({
    emotion: ['', Validators.required],
    gifFileKey: ['', Validators.required]
  });

  errorUploadMessage: string;
  uploadComplete: string;

  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private emotionService: EmotionService,
    private uploadFileService: UploadFileService,
    private router: Router,
    private errorResponseService: ErrorResponseService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id !== 'new') {
        this.titleName = 'Edit' + this.titleName;
        this.showDataOnInputField(this.id);
      } else {
        this.titleName = 'Create' + this.titleName;
      }
    });
  }

  showDataOnInputField(id) {
    this.emotionService.getEmotionById(id).subscribe(
      (response: { emotion: string, gifFileKey: string }) => {
        this.emotionForm.patchValue({
          emotion: response.emotion,
          gifFileKey: response.gifFileKey
        });
      },
      errorResponse => {
        this.utilService.openSnackBar('Fail to get emotion information.' + '\n' + this.errorResponseService.detailString(errorResponse));
      });
  }

  uploadFile(event) {
    this.uploadComplete = '';
    const file = event.target.files[0];
    const fileType = file.type;
    const fileSize = file.size;
    if (fileType !== 'image/gif' || fileSize > 5242880) {
      this.errorUploadMessage = 'Please choose .gif file type and size not over 5 MB to upload.';
    } else {
      this.errorUploadMessage = 'Uploading, please wait.';

      this.uploadFileService.uploadFileToServer('emotion', file).subscribe(
        filePath => {
          this.emotionForm.patchValue({
            gifFileKey: filePath
          });
          this.uploadComplete = 'Upload file complete.';
          this.errorUploadMessage = '';
        },
        errorResponse => {
          this.errorUploadMessage = 'Fail to upload file to server.' + '\n' + this.errorResponseService.detailString(errorResponse);
        });
    }
  }

  onSubmit() {
    if (this.emotionForm.status === 'INVALID') {
      this.utilService.openSnackBar('Please fill information in all fields.');
    } else {
      const data = this.emotionForm.value;
      if (this.id === 'new') {
        this.emotionService.createEmotion(data).subscribe(
          () => {
            this.utilService.openSnackBar('Create new emotion file successful');
            this.router.navigate(['emotion']);
          },
          errorResponse => {
            this.utilService.openSnackBar('Fail to create new emotion file.' + '\n' + this.errorResponseService.detailString(errorResponse));
          });
      } else {
        this.emotionService.editEmotion(this.id, data).subscribe(
          () => {
            this.utilService.openSnackBar('Edit emotion file successful.');
            this.router.navigate(['emotion']);
          },
          errorResponse => {
            this.utilService.openSnackBar('Fail to edit emotion file.' + '\n' + this.errorResponseService.detailString(errorResponse));
          });
      }
    }
  }

}
