import { UtilService } from './util.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseUrl } from './baseUrl';
import { AuthService } from './auth.service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private utilService: UtilService
  ) {
  }

  getAudios(pageOffset, pageSize, filter = '') {
    const currentPage = pageOffset + 1;
    let url = baseUrl.concat('/audioFiles?page=', currentPage, '&max=', pageSize);
    if (filter) {
      url = url.concat('&filterMessage=', encodeURIComponent(filter));
    }
    return this.http.get(url, this.authService.getHttpOption());
  }

  getAudioById(id) {
    const url = baseUrl + '/audioFiles/' + id;
    return this.http.get(url, this.authService.getHttpOption());
  }

  createAudio(data) {
    if (!environment.production) {
      console.log(data.audioKey);
      this.utilService.openSnackBar(`please create file audio key at:
     "obo-nlp-server/storage/${data.audioKey}"
      you can copy file name at browser console.
      You must be create the file completely before press 'OK' button.`);
    }
    const url = baseUrl + '/audioFiles';
    return this.http.post(url, data, this.authService.getHttpOption());
  }

  editAudio(id, data) {
    const url = baseUrl + '/audioFiles/' + id;
    return this.http.patch(url, data, this.authService.getHttpOption());
  }

  deleteAudio(id) {
    const url = baseUrl + '/audioFiles/' + id;
    return this.http.delete(url, this.authService.getHttpOption());
  }

  createAudioPack() {
    const url = baseUrl + '/audioPack';
    return this.http.post(url, {}, this.authService.getHttpOption());
  }
}

