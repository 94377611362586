import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {baseUrl} from './baseUrl';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RedeemCodeService {

  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) {}

  question = 'Press Enter to add "';

  getRedeemCodes(pageOffset, pageSize) {
    const url = baseUrl + '/redeemCodes/';
    return this.http.get(url, this.authService.getHttpOption());
  }

  getRedeemCodeById(id) {
    const url = baseUrl + '/redeemCodes/' + id;
    return this.http.get(url, this.authService.getHttpOption());
  }

  createRedeemCode(quantity, group) {
    const url = baseUrl + '/redeemCodes?quantity=' + quantity + '&group=' + group;
    return this.http.post(url, {}, this.authService.getHttpOption());
  }

  editRedeemCode(id, data) {
    const url = baseUrl + '/redeemCodes';
    return this.http.patch(url, data, this.authService.getHttpOption());
  }

  deleteRedeemCode(id) {
    const url = baseUrl + '/redeemCodes?id=' + id;
    return this.http.delete(url, this.authService.getHttpOption());
  }

  filterGroupsFormOptions(groupValue: string, groups: any[]) {
    if (!groups) {
      return [];
    }
    let results;
    if (!groupValue) {
      results = groups;
    } else {
      results = groups.filter(group => {
        if (group && groupValue) {
          const isStartWith = group.startsWith(groupValue);
          return isStartWith;
        }
      });
      if (!results.includes(groupValue)) {
        results.unshift(this.question + groupValue + '"?');
      }
    }
    return results;
  }

  optionSelected(option, groups, advanceFilterForm, redeemCodeService) {
    if (option.value.indexOf(redeemCodeService.question) === 0) {
      const newGroup = option.value;
      groups.unshift(newGroup);
      advanceFilterForm.value.group = newGroup;
    }
  }

  enter(filteredGroups, advanceFilterForm) {
    const value = advanceFilterForm.value.group;
    if (!filteredGroups.some(entry => entry === value)) {
      filteredGroups.push(value);
      filteredGroups.shift();
    }
    setTimeout(() => advanceFilterForm.value.group = (value));
  }

  getRedeemGroup(redeemCodes) {
    const internalGroups: string[] = [];
    for (const redeemCode of redeemCodes) {
      if (redeemCode.group) {
        redeemCode.group = redeemCode.group.trim();
        if (!internalGroups.includes(redeemCode.group)) {
          internalGroups.push(redeemCode.group);
        }
      }
    }
    return internalGroups;
  }
}
