import { Injectable } from '@angular/core';

interface ErrorResponse {
  error: {
    error: {
      message: string;
      statusCode: number;
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class ErrorResponseService {

  constructor() { }

  detailString(errorResponse: ErrorResponse) {
    const body = errorResponse.error;
    return 'Error code ' + body.error.statusCode + ' ' + body.error.message;
  }
}
