import { UtilService } from './../util.service';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder} from '@angular/forms';
import {UserService} from '../user.service';
import {ErrorResponseService} from '../error-response.service';

interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  role: string;
  createAt: number;
  isActivated: boolean;
}

@Component({
  selector: 'app-user-save',
  templateUrl: './user-save.component.html',
  styleUrls: ['./user-save.component.css']
})
export class UserSaveComponent implements OnInit {
  titleName: string;
  id: string;

  userForm = this.formBuilder.group({
    username: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    role: 'user',
    isActivated: true,
  });

  requiredFieldError: string;
  passwordError: string;
  confirmPasswordError: string;
  

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private errorResponseService: ErrorResponseService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id !== 'new') {
        this.titleName = 'Edit User';
        this.showDataOnInputField();
      } else {
        this.titleName = 'Create Admin User';
      }
    });
  }

  onSubmit() {
    if (this.id === 'new') {
      if (this.checkRequiredField() && this.checkPassword() && this.checkConfirmPassword()) {
        this.createUser();
      }
    } else {
      if (this.checkRequiredField()) {
        if (this.userForm.value.password) {
          if (this.checkPassword() && this.checkConfirmPassword()) {
            this.editUser();
          }
        } else {
          this.editUser();
        }
      }
    }
  }

  checkRequiredField() {
    const username = this.userForm.value.username;
    const password = this.userForm.value.password;
    if (this.id === 'new') {
      if (username && password) {
        this.requiredFieldError = '';
        return true;
      } else {
        this.requiredFieldError = 'Require username and password.';
        return false;
      }
    } else {
      if (username) {
        this.requiredFieldError = '';
        return true;
      } else {
        this.requiredFieldError = 'Require username.';
        return false;
      }
    }
  }

  checkPassword() {
    const password = this.userForm.value.password;
    const regex = /^(?=.{8,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).*$/;
    if (regex.test(password)) {
      this.passwordError = '';
      return true;
    } else {
      this.passwordError = 'Password must have at least 8 characters. Include uppercase, lowercase and number.';
      return false;
    }
  }

  checkConfirmPassword() {
    const password = this.userForm.value.password;
    const confirmPassword = this.userForm.value.confirmPassword;
    if (password === confirmPassword) {
      this.confirmPasswordError = '';
      return true;
    } else {
      this.confirmPasswordError = 'Password and confirm password don\'t match.';
      return false;
    }
  }

  showDataOnInputField() {
    this.userService.getUserById(this.id).subscribe(
      (response: User) => {
        this.requiredFieldError = '';
        this.userForm.patchValue({
          username: response.username,
          firstName: response.firstName,
          lastName: response.lastName,
          role: response.role,
          isActivated: response.isActivated,
        });
      },
      errorResponse => {
        this.requiredFieldError = 'Fail to get user information.' + '\n' + this.errorResponseService.detailString(errorResponse);
      });
  }

  createUser() {
    const data = {
      username: this.userForm.value.username,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      password: this.userForm.value.password,
      role: 'admin',
      isActivated: this.userForm.value.isActivated
    };
    this.userService.createUser(data).subscribe(
      () => {
        this.utilService.openSnackBar('Create new admin user successful');
        this.router.navigate(['user']);
      },
      errorResponse => {
        this.utilService.openSnackBar('Fail to create admin user.' + '\n' + this.errorResponseService.detailString(errorResponse));
      });
  }

  editUser() {
    const data = {
      username: this.userForm.value.username,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      password: this.userForm.value.password,
      role: this.userForm.value.role,
      isActivated: this.userForm.value.isActivated
    };
    this.userService.editUser(this.id, data).subscribe(
      () => {
        this.utilService.openSnackBar('Edit user successful');
        this.router.navigate(['user']);
      },
      errorResponse => {
        this.utilService.openSnackBar('Fail to edit user.' + '\n' + this.errorResponseService.detailString(errorResponse));
      });
  }
}
