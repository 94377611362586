import { UtilService } from './../util.service';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../auth.service';

@Component({
  selector: 'app-oauth-callback',
  templateUrl: './oauth-callback.component.html',
  styleUrls: ['./oauth-callback.component.css']
})
export class OauthCallbackComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.saveToken();
  }

  saveToken() {
    this.activatedRoute.fragment.subscribe(fragment => {
      const token = new URLSearchParams(fragment).get('id_token');
      if (this.authService.checkNonce(token)) {
        if (this.authService.checkRole(token)) {
          this.authService.saveToken(token);
          this.router.navigate(['']);
        } else {
          this.utilService.openSnackBar('This site is for admin only.');
          this.router.navigate(['login']);
        }
      } else {
        this.utilService.openSnackBar('Nonce is not match. Try login again.');
        this.router.navigate(['login']);
      }
    });
  }

}
